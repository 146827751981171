<template>
  <div class="app-container">
    <solution />
  </div>
</template>

<script>
import Solution from 'src/components/Solution/index.vue'

export default {
  components: {
    Solution,
  },
}
</script>