<template>
  <div class="step_3">
    <div class="info">
      <div class="item">
        <span class="name">客户：</span>
        <span class="val">{{ step_1.cname }}</span>
      </div>
      <div class="item">
        <span class="name">门店：</span>
        <span class="val">{{ step_1.shopName }}</span>
      </div>
      <div class="item">
        <span class="name">类型：</span>
        <span class="val">{{ formatLabel(productTypeList, step_1.type) }}</span>
      </div>
    </div>
    <el-table ref="singleTable" v-loading="isFetching" :data="step_2" row-key="index">
      <el-table-column
        v-for="(item, index) in columns"
        :min-width="item.width"
        :key="index"
        :label="item.label"
        :prop="item.key"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <template v-if="item.key === 'status'">
            <status :data="scope.row.status" />
          </template>
          <template v-else-if="item.key === 'boxType'">
            <span>{{ formatLabel(productTypeList, scope.row.boxType) }}</span>
          </template>
          <div v-else-if="item.key === 'operation'">
            <el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
            <el-button @click="restSecond(scope.row)" type="text" size="small">摄像头管理</el-button>
            <el-button @click="restSecond(scope.row)" type="text" size="small">设备信息查询</el-button>
          </div>
          <div v-else>{{ isEmpty(scope.row[item.key]) ? '--' : scope.row[item.key] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-button class="cancel" @click="() => this.$emit('change', 1)">上一步</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { columns1 } from '../common'
import { isEmpty } from 'src/utils/method'
import Status from 'src/components/Status'
import { productTypeList } from 'src/utils/dict'
import { formatLabel } from 'src/utils/common'

export default {
  components: {
    Status,
  },
  data() {
    return {
      columns: columns1,
      isFetching: false,
      productTypeList,
    }
  },
  computed: {
    ...mapState({
      step_1: (state) => state.solution.step_1,
      step_2: (state) => state.solution.step_2,
    }),
  },
  methods: {
    isEmpty,
    formatLabel,
    handleConfirm() {
      this.$message.success('创建成功')
      this.$router.back()
    },
  },
}
</script>

<style lang="less" scoped>
.info {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  .item:not(:last-child) {
    margin-right: 60px;
    .name {
      font-weight: 500;
    }
  }
}
.footer {
  text-align: center;
  margin-top: 60px;
  .cancel {
    margin-right: 20px;
  }
}
</style>