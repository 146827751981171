<template>
  <el-popover v-model="visible" placement="bottom-start" width="400" trigger="click">
    <el-input class="filter-input" placeholder="输入组织名称搜索" v-model="filterText"> </el-input>
    <div class="tree-container">
      <el-tree
        class="filter-tree"
        ref="tree"
        v-loading="loading"
        node-key="orgcode"
        :data="data"
        :props="defaultProps"
        :highlight-current="true"
        :default-expanded-keys="expandedKeys"
        :default-checked-keys="[]"
        :current-node-key="currentNodeKey"
        :filter-node-method="filterNodeMethod"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
    <el-input class="reference" slot="reference" readonly="readonly" placeholder="请选择" v-model="label"></el-input>
    <div class="btns">
      <el-button type="primary" :disabled="disabled" @click="onConfirm">确定</el-button>
      <el-button @click="onCancel">取消</el-button>
    </div>
  </el-popover>
</template>

<script>
import debounce from 'lodash/debounce'
import { isEmpty } from 'src/utils/method'

export default {
  props: {
    data: [Array],
    loading: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    defaultSelect: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    filterNodeMethod: {
      type: Function,
      default: () => {},
    },
    defaultExpandedKeys: [Array],
  },
  data() {
    return {
      isFetching: false,
      filterText: null,
      defaultProps: {
        label: 'name',
        children: 'sublist',
      },
      label: '',
      visible: false,
      searching: false,
      expandedKeys: [],
      selectedNode: null,
      disabled: true,
    }
  },
  computed: {
    currentNodeKey() {
      return this.value
    },
  },
  watch: {
    data: {
      handler(arr) {
        if (arr.length === 0) return
        // if (this.searching && !isEmpty(this.filterText)) {
        //   this.expandedKeys = this.formatExpandKeys(val)
        // }
        // if (isEmpty(this.filterText)) {
        //   this.expandedKeys = []
        // }
        // if (this.defaultSelect) {
        //   this.$nextTick(() => {
        //     const first = this.getFirstItem(val)
        //     this.generateLabel(first)
        //   })
        // }
        // if (!isEmpty(this.value)) {
        //   this.$nextTick(() => {
        //     const currNode = this.$refs.tree.getCurrentNode()
        //     this.generateLabel(currNode)
        //     this.expandedKeys = [this.value]
        //   })
        // }
      },
      deep: true,
    },
    visible(val) {
      if (val) {
        this.expandedKeys = this.defaultExpandedKeys
      } else {
        this.searching = false
        // this.expandedKeys = []
        this.filterText = null
      }
    },
    filterText(val, oldVal) {
      if (val !== oldVal) {
        this.onFilter()
      }
    },
    value: {
      handler: function (val) {
        if (isEmpty(val)) {
          this.label = ''
        } else {
          // this.expandedKeys = [val]
        }
      },
      immediate: false,
    },
    label(val) {
      if (isEmpty(val)) {
        this.selectedNode = null
      }
    },
    selectedNode(node) {
      // orgType 1: 门店
      this.disabled = node && node.data?.orgType === 1 ? false : true
    },
  },

  async mounted() {
    if (this.immediate) {
      this.emitData()
    }
  },

  methods: {
    generateLabel(node) {
      const n = this.$refs.tree.getNode(node)
      const items = this.formatSelectData(n)
      this.$emit('selectedData', items)
      this.label = items.map((item) => item.label).join('/')
    },
    getFirstItem(data) {
      let val = data[0]
      while (val.children) {
        val = val.children && val.children[0]
      }
      return val
    },
    clear() {
      this.label = ''
      this.visible = false
    },
    formatExpandKeys(data) {
      const keys = []
      const iterate = function (arr) {
        arr.forEach((item) => {
          keys.push(item.id)
          if (item.children) {
            iterate(item.children)
          }
        })
      }
      iterate(data)
      return keys
    },
    formatSelectData(node) {
      let items = []
      if (node.level === 3) {
        let curr = node
        while (curr && curr.level >= 1) {
          items[curr.level - 1] = curr.data
          curr = curr.parent
        }
      } else {
        items = []
      }
      return items
    },
    handleNodeClick(obj, node) {
      this.selectedNode = node
    },
    onFilter: debounce(function () {
      // this.searching = true
      this.$refs.tree.filter(this.value)
    }, 300),
    // onFocus() {
    //   this.emitData()
    // },
    emitData() {
      this.$emit('fetchData', { keyword: this.filterText })
    },
    onConfirm() {
      //   const items = this.formatSelectData(this.selectedNode)
      const node = this.selectedNode
      if (node && node.isLeaf) {
        this.$emit('selectedData', node)
        this.label = node.data.name
        this.visible = false
      }
    },
    onCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.filter-input {
  padding-bottom: 12px;
}
.tree-container {
  max-height: 400px;
  overflow: auto;
}
.el-tree {
  width: auto;
}
.btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}
</style>