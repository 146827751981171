export const columns1 = [
  {
    label: '盒子编号',
    key: 'boxNo',
  },
  {
    label: '状态',
    key: 'status',
  },
  {
    label: '盒子MAC',
    key: 'macId',
  },
  {
    label: '端口号',
    key: 'boxPort',
  },
  {
    label: '盒子类型',
    key: 'boxType',
  },
  {
    label: '型号',
    key: 'boxModel',
  },
  {
    label: '摄像头数量',
    key: 'cameraCount',
    sortable: true,
  },
]